.divider { 
  width: 2px;
  height: 40px;
  opacity: 0.3;
  background: #5D7279;
  margin: 0 10px; /* Adjust the margin as needed to control the spacing */
}
.dividerChart { 
  width: 2px;
  height: 35px;
  opacity: 0.3;
  background: #5D7279;
  margin: 0 10px; /* Adjust the margin as needed to control the spacing */
}